import * as React from 'react';
import styles from './404.module.scss';
import DefaultLayout from '../layouts/Default';
import { Link } from 'gatsby';

export default () => (
  <DefaultLayout>
    <div className={styles.content}>
      <h1 className={styles.header}>Page not found</h1>
      <p className={styles.errorMessage}>
        The page you are looking for does not exists.
      </p>
      <Link to="/">Home</Link>
    </div>
  </DefaultLayout>
);
